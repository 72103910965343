<div style="padding-bottom: 120px">
  
  
  <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel" data-bs-interval="5000" data-bs-pause="false">  
    <div class="carousel-inner">
      <div class="carousel-item active bg-red">
         <app-hero [home]="true"></app-hero>
      </div>
      <div class="carousel-item bg-green">
        <section class="bg-half d-table w-100" id="home">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-7 col-md-7 text-center" data-aos="fade-right">
                <div class="title-heading me-lg-4">
                  <h1 class="heading mb-1">CRÉDITOS EN MINUTOS</h1>
                  <h2 class="text-primary">FÁCIL Y RÁPIDO</h2>
                  <p class="para-desc">Préstamos personales de hasta 15.000.000Gs. Pagaderos hasta en 18 cuotas con mínimos requisitos.</p>
                  <div class="mt-4">
                    <a routerLink="/credito" class="btn btn-pills btn-primary"><b>SOLICITAR AHORA</b></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>  
    
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="1" aria-label="Slide 2"></button>
    </div>
     
    <div class="prev-next-container">
      <button class="btn-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
        <span class="prev-icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48" class="arrow-left">
            <path d="M15 19l-7-7 7-7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
  
      </button>
      <button class="btn-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
        <span class="next-icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48" class="arrow-right">
            <path d="M9 5l7 7-7 7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
      </button> 
    </div>
</div> 

  <section class="section bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center" data-aos="zoom-in">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">NUESTROS SERVICIOS</h4> <i class="fa fa-angle-right" aria-hidden="true"></i>
            <p class="para-desc mb-0 mx-auto">Gracias a nuestras <b>+70 sucursales y nuestra APP</b> conectada a la <b>Red Infonet</b> tenés cobertura en todo el Paraguay para: </p>
          </div>
        </div>
      </div>

      <div class="">
        <div class="row">
          <div class="col-md-3 col-6 mt-4 pt-2">
            <div class="counter-box text-center">
              <img src="assets/images/icono/icono-credito.png" alt="" class="img-about">
              <p class="title-home">SIMULAR Y SOLICITAR TU CRÉDITO</p>
              <p class="subtitle-about">En sucursal y en la APP</p>
              <p class="subtitle-about">Via Whatsapp</p>
            </div>
          </div>

          <div class="col-md-3 col-6 mt-4 pt-2">
            <div class="counter-box text-center">
              <img src="assets/images/icono/icono-retirar.png" alt="" class="img-about">
              <p class="title-home">RETIRAR TU DINERO</p>
              <p class="subtitle-about">en <b>+100 cajeros infonet</b> con <b>nuestra APP</b> o <b>en +70 sucursales</b></p>
            </div>
          </div>

          <div class="col-md-3 col-6 mt-4 pt-2">
            <div class="counter-box text-center">
              <img src="assets/images/icono/icono-smartphone.png" alt="" class="img-about">
              <p class="title-home">PAGAR +1.000 SERVICIOS</p>
              <p class="subtitle-about">públicos y privados</p>
            </div>
          </div>

          <div class="col-md-3 col-6 mt-4 pt-2">
            <div class="counter-box text-center">
              <img src="assets/images/icono/icono-pago_qr.png" alt="" class="img-about">
              <p class="title-home">PAGO CON QR</p>
              <p class="subtitle-about">en <b>+51.000 comercios</b> con nuestra APP</p>
            </div>
          </div>

        </div>

        <div class="col-12 text-center">
          <div class="mt-5">
                      <a href="https://play.google.com/store/apps/details/Crediagil?id=py.com.procesa.crediagilapp&hl=es_CL" target="_blank" title="Descarga APP"
                         class="btn-custom btn-primary me-3"><i class="bi bi-download p-1"></i><b>DESCARGAR APP</b></a>
                      <a routerLink="/sucursales" class="btn-custom btn-primary" title="Sucursales" style="background: #EB7100; border-color:#EB7100 "><i class="bi bi-geo-alt p-1"></i><b>VER SUCURSALES</b></a>
<!--            <button type="button"   class="btn btn-pills btn-secondary btn-sm">DESCARGAR APP</button>-->
<!--            <button type="button" routerLink="/offices" class="btn btn-pills btn-secondary btn-sm" style="background: #EB7100">VER SUCURSALES</button>-->
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>

  <!-- MINI CREDITOS -->
  <section class="section  minicreditos">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7 col-md-7 text-center" data-aos="zoom-in">
          <img src="./assets/images/minicreditos/minicreditos-banner.jpg" alt="" style="max-height: 320px;">
           <div class="text-center mt-4">
             <h2 class="title-mini-creditos">Solo disponibles en nuestra app</h2>
             <div class="mt-4">
               <a class="btn btn-pills btn-primary" routerLink="/minicreditos" href="/minicreditos"><b>CONOCÉ MÁS</b></a>
             </div>
           </div>
        </div>

      </div>
    </div>
  </section>

  <!-- CREDI CLU -->
  <section class="section bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7 col-md-7 text-center" data-aos="zoom-in">
          <img src="assets/images/frediagil/frediclu.svg" alt="" style="max-height: 450px;">
           <div class="text-center mt-4">
             <h2 class="title-fredi-clu">Beneficio exclusivo para clientes</h2>
<!--             <div class="btn d-flex justify-content-center align-items-center">-->
<!--               <h3 class="title-fredi-clu">Acceder</h3>-->
<!--               <img src="assets/images/icono/acceder.png" class="me-2 icon-click img-acceder" alt="Acceder">-->
<!--             </div>-->
             <div class="mt-4">
               <a class="btn btn-pills btn-primary" href="http://www.frediclu.com.py/" target="_blank"><b>ACCEDER</b></a>
             </div>
           </div>
        </div>

      </div>


    </div>
  </section>

  <section class="section ">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center" data-aos="zoom-in">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">SOBRE NOSOTROS</h4>
            <p class="para-desc mb-0 mx-auto">Somos la casa de crédito <b>más grande</b> del Paraguay. </p>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->

      <div class="row">
        <div class="col-md-3 col-6 mt-4 pt-2">
          <div class="counter-box text-center">
            <img src="assets/images/icono/icono-trayectoria.png" alt="" class="img-about">
            <p class="title-about">+18</p>
            <p class="subtitle-about">AÑOS</p>
          </div>
        </div>


        <div class="col-md-3 col-6 mt-4 pt-2">
          <div class="counter-box text-center">
            <img src="assets/images/icono/icono-Clientes.png" alt="" class="img-about">
            <p class="title-about">+140.000</p>
            <p class="subtitle-about">CLIENTES</p>
          </div>
        </div>

        <div class="col-md-3 col-6 mt-4 pt-2">
          <div class="counter-box text-center">
            <img src="assets/images/icono/icono-colaboradores.png" alt="" class="img-about">
            <p class="title-about">+1.500</p>
            <p class="subtitle-about">COLABORADORES</p>
          </div>
        </div>

        <div class="col-md-3 col-6 mt-4 pt-2">
          <div class="counter-box text-center">
            <img src="assets/images/icono/icono-sucursales.png" alt="" class="img-about">
            <p class="title-about">+70</p>
            <p class="subtitle-about">SUCURSALES</p>
          </div>
        </div>

      </div>
      <div class="col-lg-12 col-md-12 text-center">
        <div class="title-heading me-lg-4">
          <div class="mt-5">
            <a routerLink="/nosotros" class="btn btn-pills btn-primary"><b>MÁS SOBRE NOSOTROS</b></a>
          </div>
        </div>
      </div>


      <!--end row-->
    </div>
    <!--end container-->
  </section>

  <section class="section bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12" data-aos="zoom-in">
          <div class="section-title text-center mb-4 pb-2">
            <h4 class="title mb-4">TESTIMONIOS DE NUESTROS CLIENTES</h4>
          </div>
        </div><!--end col-->
      </div><!--end row-->

      <div class="row">
        <div class="col-6 col-lg-3 col-md-3">
          <div class="card mb-4 rounded">
            <img class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" src="./assets/images/testimonio/testimonio_1.png" alt="">
            <p class="text-center-testimonial-content p-4">Necesitaba sacar un crédito urgente y Credi Ágil me salvó con eso, el simulador de préstamos de la web funciona super bien!</p>
            <p class="text-center"><b>Viviana Ramirez</b></p>
          </div>
        </div>

        <div class="col-6 col-lg-3 col-md-3" >
          <div class="card mb-4 rounded">
            <img class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" src="./assets/images/testimonio/tesmonio_2.png" alt="">
            <p class="text-center-testimonial-content p-4">Credi Ágil siempre es mi primera opción en préstamos personales, nunca tuve problema para sacar un crédito con ellos...</p>
            <p class="text-center"><b>Luis Gonzalez</b></p>
          </div>
        </div>

        <div class="col-6 col-lg-3 col-md-3">
          <div class="card mb-4 rounded">
            <img class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" src="./assets/images/testimonio/testimonio_3.png" alt="">
            <p class="text-center-testimonial-content p-4">Credi Ágil me ahorró tiempo y combustible con la opción de préstamos online, confirmo que es la mejor casa de crédito del país!</p>
            <p class="text-center"><b>Ramón Diaz</b></p>
          </div>
        </div>

        <div class="col-6 col-lg-3 col-md-3">
          <div class="card mb-4 rounded">
            <img class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" src="./assets/images/testimonio/tesmitonio_4.png" alt="">
            <p class="text-center-testimonial-content p-4">Estaba buscando sacar préstamos personales y pude en la web de Credi Ágil, rápido y fácil. Realmente te dan préstamos al toque!</p>
            <p class="text-center"><b>Carmen Benítez</b></p>
          </div>
        </div>

      </div>
    </div>
  </section>

</div>


