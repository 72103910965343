import { Component, inject, OnInit} from '@angular/core';
import {OfficesService} from "../../services/offices.service";
import {CommonModule, JsonPipe} from "@angular/common";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {NavigationEnd, Router} from "@angular/router";
import {GoogleTagManagerService} from "angular-google-tag-manager";

@Component({
  selector: 'app-offices',
  standalone: true,
  imports: [CommonModule, JsonPipe, ReactiveFormsModule],
  templateUrl: './offices.component.html',
  styleUrl: './offices.component.css'
})
export class OfficesComponent implements OnInit{
  search = new FormControl('');
  officesService = inject(OfficesService);
  offices: any[] = [];
  officesFilter: any[] = [];
  constructor(
    private  gtmService: GoogleTagManagerService,
    private router: Router,
  ) {
    this.search.valueChanges.pipe().subscribe((value) => {
      if (typeof value === "string") {
        this.officesFilter = this.offices.filter((office: {
            nombre: string;
            direccion: string;
          }) =>
            office.nombre.toLowerCase().includes(value.toLowerCase()) ||
            office.direccion.toLowerCase().includes(value.toLowerCase())
        );
      }
    });
  }
  getSucursales() {
    this.officesService.getSucursales().subscribe((data:any) => {
      this.offices = data['data'];
      this.officesFilter = data['data'];
    });
  }

  ngOnInit(): void {
    this.getSucursales();
    this.router.events.forEach(item =>{
      if(item instanceof NavigationEnd){
        console.log("NavigationEnd",item);
        const gtmTag = {
          event: "sucursales",
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    })

    if (typeof document !== 'undefined') {
      const element = document.querySelector('.btn-wsp') as HTMLElement;
      if (element) {
        element.style.display = 'block';
      }

      const footer = document.querySelector('.footer') as HTMLElement;
      if (footer) {
        footer.style.position = 'relative';
      }
    }
  }

}
