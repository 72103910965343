
<!--<section class="section">
  <div class="container">
    <main class="main-content">
      <h1>SIMULADOR DE PRÉSTAMOS</h1>
      <p class="subtitle">¡Simulá y obtén tu préstamo al toque!</p>

      <section class="loan-amount">
        <h2>¿CUÁNTO NECESITÁS?</h2>
        <p class="amount">Gs 3.000.000</p>
        <p class="max-amount">Podés pedir hasta Gs 15.000.000</p>
        @if(isBrowser){
        <ngx-slider [(value)]="valueImporte" [options]="optionsImporte"></ngx-slider>
        }
&lt;!&ndash;        <div class="slider-container">&ndash;&gt;
&lt;!&ndash;          <input type="range" min="1000000" max="15000000" value="3000000" class="slider" id="amountRange">&ndash;&gt;
&lt;!&ndash;        </div>&ndash;&gt;

&lt;!&ndash;        <div>&ndash;&gt;
&lt;!&ndash;          <label for="range1" class="form-label">Podés pedir hasta Gs 15.000.000</label>&ndash;&gt;
&lt;!&ndash;          <input type="range" class="form-range format-range" id="range1" min="1" max="15" step="1" >&ndash;&gt;
&lt;!&ndash;        </div>&ndash;&gt;
      </section>

&lt;!&ndash;      <div class="field-group">&ndash;&gt;
&lt;!&ndash;        <label for="range" class="range-label">Prueba</label>&ndash;&gt;
&lt;!&ndash;        <input type="range" name="range" id="range" class="range-field" value="0" min="0" max="15">&ndash;&gt;
&lt;!&ndash;      </div>&ndash;&gt;

      <section class="loan-terms">
        <h2>¿EN CUÁNTAS CUOTAS QUERÉS PAGAR?</h2>
        @if(isBrowser){
          <ngx-slider [(value)]="valueCuota" [options]="optionsCuota"></ngx-slider>
        }
&lt;!&ndash;        <ngx-slider [(value)]="value" [options]="options"></ngx-slider>&ndash;&gt;
&lt;!&ndash;        <div class="slider-container">&ndash;&gt;
&lt;!&ndash;          <input type="range" min="6" max="18" step="6" value="12" class="slider" id="termsRange">&ndash;&gt;
&lt;!&ndash;        </div>&ndash;&gt;
      </section>

      <section class="installments">
        <p>12 CUOTAS DE</p>
        <p class="installment-amount">Gs 633.000</p>
        <button class="btn btn-pills btn-primary">SOLICITAR AHORA</button>
      </section>
    </main>
  </div>

</section>-->
<section class="section mt-4">
  <div class="row mt-n5 mt-sm-n7 mt-md-n8">
    <div class="col-11 col-lg-4 mx-auto">
      <div class="card shadow   p-4 p-sm-5 p-md-6">
        <!-- Card header -->
        <div class="text-center">
          <h3 class="card-title">SIMULADOR DE PRÉSTAMOS</h3>
          <p class="card-subtitle">¡Simulá y obtené tu préstamo al toque!</p>
        </div>
        <!-- Card body & form -->
        <form [formGroup]="creditForm"  class="card-body px-0 pb-0 pt-5">
          <p class="text-center">¿CUÁNTO NECESITÁS?</p>
          <p class="text-center">Podés pedir hasta Gs 15.000.000</p>
          <div class="input-floating-label form-floating mb-4">
            <select class="form-select form-control" aria-label="Default select example" id="floatingMonto"
                    formControlName="monto" (change)="getCuotas()">
              @for (monto of montos;track 1){
              <option value="{{ monto.value }}">{{ monto.viewValue}}</option>
              }
            </select>
            <label for="floatingMonto">Monto</label>
          </div>

          <p class="text-center">¿EN CUÁNTAS CUOTAS QUERÉS PAGAR?</p>
          <div class="input-floating-label form-floating mb-4">
            <select class="form-select form-control" aria-label="Default select example" id="floatingPlazo"
                    formControlName="plazo"  (change)="getCuotas()">
              @for (plazo of plazos;track 1){
              <option value="{{ plazo.value }}" >{{ plazo.viewValue }}</option>
              }
            </select>
            <label for="floatingPlazo">Plazo</label>
          </div>

          <!-- Cuota -->
<!--          <div class="input-floating-label form-floating mb-4">-->
<!--            <input type="text" class="form-control" id="floatingCuota" placeholder="Cuota"-->
<!--                   formControlName="cuota" [dropSpecialCharacters]="true" thousandSeparator="." mask="separator" readonly>-->
<!--            <label for="floatingCuota">Cuota</label>-->
<!--          </div>-->


        </form>
      </div>
    </div>
  </div>

  <div class="section-two bg-light mt-3">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="title-heading">
          <p class="para-desc mb-0 mx-auto ">{{getCant()}} CUOTAS DE</p>
          <h1 class="mb-0 mx-auto importe-text" >Gs. {{getImporteCuota()}}</h1>
          <div class="text-center">
            <button class="btn btn-pills btn-primary btn-send" (click)="getCredito()">SOLICITAR AHORA</button>
          </div>

        </div>
      </div>
      <!--end col-->
    </div>
  </div>

</section>
