import {AfterViewInit, Component, inject, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {NgxSliderModule, Options} from "@angular-slider/ngx-slider";
import {DecimalPipe, isPlatformBrowser, NgClass} from "@angular/common";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {NgxMaskDirective} from "ngx-mask";
import {CreditService} from "../../services/credit.service";
import {NavigationEnd, Router, RouterLink} from "@angular/router";
import {GoogleTagManagerService} from "angular-google-tag-manager";

@Component({
  selector: 'app-simulator',
  standalone: true,
  imports: [NgxSliderModule, ReactiveFormsModule, NgClass, NgxMaskDirective, RouterLink, DecimalPipe],
  templateUrl: './simulator.component.html',
  styleUrl: './simulator.component.css'
})
export class SimulatorComponent  implements OnInit,AfterViewInit{
  creditService = inject(CreditService);
  //router = inject(Router);
  montos: any[] = this.creditService.getMontos(1000000,15000000,1000000);
  plazos: any[] = this.creditService.getPlazos();
  creditForm!: FormGroup;
  isBrowser: boolean;

  constructor(
    private fb: FormBuilder,
    private gtmService: GoogleTagManagerService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId:Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.createCreditForm();
  }

  ngOnInit(): void {
    this.getCuotas();
    //this.getTokenForm();
    this.router.events.forEach(item =>{
      if(item instanceof NavigationEnd){
        console.log("NavigationEnd",item);
        const gtmTag = {
          event: "simulador",
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    });

    if (typeof document !== 'undefined') {
      const element = document.querySelector('.btn-wsp') as HTMLElement;
      if (element) {
        element.style.display = 'block';
      }

      const footer = document.querySelector('.footer') as HTMLElement;
      if (footer) {
        footer.style.position = 'relative';
      }
    }
  }

  createCreditForm(){
    this.creditForm = this.fb.group({
      monto: this.fb.control<number | null>(this.montos[2].value, Validators.required),
      plazo: this.fb.control<number | null>(this.plazos[1].value, Validators.required),
      cuota: [''],
      cuotaDisplay: [''],
    });
  }

  getCuotas() {
    this.creditService.getCuotas(this.creditForm.value.monto, this.creditForm.value.plazo)
      .subscribe((data:any) => {
        console.log(data.montoCuota)
        this.creditForm.patchValue({cuota: data.montoCuota});
      });
  }

  getImporteCuota() {
    const formattedCuota = this.creditForm.value.cuota.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return formattedCuota;
  }

  getCant(){
    return this.creditForm.value.plazo;
  }


  valueCuota: number = 5;
  optionsCuota: Options = {
    showTicksValues: true,
    stepsArray: [
      { value: 6 },
      { value: 12 },
      { value: 15 },
      { value: 18 },
    ]
  };

  valueImporte: number = 1000000;
  optionsImporte: Options = {
    showTicksValues: false,
    showTicks: false,
    translate: (value: number): string => {
      return 'Gs. ' + value;
    },
    stepsArray: [
      { value: 1000000 },
      { value: 2000000 },
      { value: 3000000 },
      { value: 4000000 },
      { value: 5000000 },
      { value: 6000000 },
      { value: 7000000 },
      { value: 8000000 },
      { value: 9000000 },
      { value: 10000000 },
      { value: 11000000 },
      { value: 12000000 },
      { value: 13000000 },
      { value: 14000000 },
      { value: 15000000 },
    ]
  };

  ngAfterViewInit(): void {
  }

  getCredito() {
    console.log('CREDITO', this.creditForm.value);
    this.creditService.setCreditoSimulado(this.creditForm.value)
    this.router.navigate(['/credito']);
  }
}
