<div>
  <footer class="footer" style="background: #0037C6">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="text-center">
            <a href="/" class="logo-footer">
              <img src="assets/images/logo_hv.png" height="49" alt="">
            </a>
            <p class="mt-1 para-desc mx-auto">En CREDI ÁGIL estamos comprometidos a proporcionarte soluciones que se adapten a tus necesidades y te impulsen hacia el éxito.</p>
            <ul class="list-unstyled mb-0 mt-0">
              <li class="list-inline-item">
                <a href="https://www.facebook.com/Crediagil.py/?locale=es_LA" class="text-white" style="font-size: 22px;" title="Facebook">
                  <i class="bi bi-facebook fa-lg"></i>
                </a>

              </li>
              <li class="list-inline-item ms-1">
                <a href="https://www.instagram.com/crediagil.py/?hl=es" class="text-white" style="font-size: 22px;" title="Instagram">
                  <i class="bi bi-instagram fa-10x"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</div>


<!--<div class="footer">-->
<!--  <div class="container">-->
<!--    <div class="row justify-content-center">-->
<!--      <a href="/" class="logo-footer">-->
<!--        <img src="assets/images/logo_hv.png" height="49" alt="">-->
<!--      </a>-->
<!--      <p>En CREDI ÁGIL estamos comprometidos a proporcionarte soluciones que se adapten a tus necesidades y te impulsen hacia el éxito.</p>-->
<!--      <br>-->
<!--      <a href="#"><img src="path/to/facebook-icon.png" alt="Facebook" width="30" height="30"></a>-->
<!--      <a href="#"><img src="path/to/instagram-icon.png" alt="Instagram" width="30" height="30"></a>-->
<!--    </div>-->
<!--  </div>-->

<!--</div>-->
