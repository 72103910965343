<section class="bg-half-170 d-table w-100" style="background: url('assets/images/sucursales1.jpg') top;" id="home">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading title-heading">
          <h2 class="text-white title-dark"> Nuestras sucursales </h2>
          <p class="text-white-50 para-desc mb-0 mx-auto">Más de 70 en todo el país</p>
        </div>
      </div><!--end col-->
    </div><!--end row-->
  </div> <!--end container-->
</section><!--end section-->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>

<!-- Start -->
<section class="section">
  <div class="container">
    <div class="row">
      <div class="subcribe-form mb-4">
        <form>
          <input name="nombre" id="nombre" type="text" class="form-control rounded-pill shadow"
                 placeholder="Nombre o Dirección :" required [formControl]="search">
          <button type="submit" class="btn btn-pills btn-primary">Buscar</button>
        </form>
      </div>
      @for (item of officesFilter; track item.nombre) {
      <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
       <div class="card nft nft-primary nft-creator border-0 rounded-md shadow" style="height:285px;">
         <div class="card-body p-3">
           <div class="pb-3 border-bottom">
             <div class="d-flex justify-content-between align-items-center">
               <span class="badge bg-soft rounded-pill">N°: {{item.numero}} | {{item.nombre}}</span>

               <a href="https://www.google.com/maps/search/?api=1&query={{item.coordenadas}}&zoom=20" target="_blank" class="text-dark h5 mb-0 read-more"><i
                 class="bi bi-geo-alt-fill"></i></a>
<!--                 <a href="https://www.google.com/maps/search/?api=1&query={{item.coordenadas}}&zoom=20" target="_blank">link</a>-->
<!--                 <a href="https://www.google.com/maps/search/?api=1&query={{item.coordenadas}}&zoom=20" target="_blank">link</a>-->
<!--                 <a href="https://maps.google.com/?ll=19.10711285128303,-98.27150480793455&z=20" target="_blank"><img height="25px" src="img/google-maps.png"/></a>-->

             </div>
           </div>
       <div class="content mt-3">
         <div class="position-relative text-center">
           <img src="assets/images/sucursal(1).svg" class="avatar avatar-small rounded-pill shadow"
                alt="">

           <div class="row author mt-2">
             <a routerLink="/account-profile" class="text-dark h6 name">{{item.direccion}}</a>
             <a class="d-block fw-bold mt-1" href="tel:{{item.telefono}}" class="text-foot">
               <i class="bi bi-telephone-fill me-2"></i>{{item.telefono}}
      </a>
    </div>
  </div>
</div>
</div>
</div>
</div>
<!--end col-->
      }@empty{
      <h3><strong>No se encontraron sucursales que coincidan con los criterios de búsqueda.</strong></h3>
      }


    </div><!--end row-->
  </div><!--end container-->
</section><!--end section-->
<!-- End -->
